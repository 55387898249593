<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="600px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="130px">
      <el-form-item label="语言/language" prop="language">
        <el-select v-model="form.language" filterable placeholder="请选择" style="width: 200px;">
          <el-option
            v-for="item in languages"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据值" prop="value">
        <el-input v-model="form.value" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="标签" prop="label">
        <el-input v-model="form.label" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-input v-model="form.type" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="描述">
        <el-input type="textarea" autosize v-model="form.description" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="排序号">
        <el-input v-model="form.sort" style="width: 80px;"/>
      </el-form-item>
      <el-form-item label="删除标记" prop="iframe">
        <el-radio v-model="form.delFlag" label="1">是</el-radio>
        <el-radio v-model="form.delFlag" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="备注信息">
        <el-input type="textarea" autosize v-model="form.remarks" style="width: 370px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/system/dict'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,
      form: {
        id: '',
        language:'',
        value: '',
        label: '',
        type: '',
        description: '',
        sortNo: '',
        delFlag: '0',
        remarks: '',
        createTime: '',
        updateTime: ''
      },
      rules: {
        language: [
          { required: true, message: '请选择语言', trigger: 'change' },
        ],
        value: [
          { required: true, message: '值不能为空', trigger: 'blur' },
          { min: 1, message: "长度不少于1个字符", trigger: "blur" }
        ],
        label: [
          { required: true, message: '标签不能为空', trigger: 'blur' },
          { min: 1, message: "长度不少于1个字符", trigger: "blur" }
        ],
        type: [
          { required: true, message: '类型不能为空', trigger: 'blur' },
          { min: 1, message: "长度不少于1个字符", trigger: "blur" }
        ],
      },
      languages: [{
        value: 'zh_CN',
        label: '中文/chinese'
      }, {
        value: 'en_US',
        label: '英语/english'
      }],
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        language:'',
        value: '',
        label: '',
        type: '',
        description: '',
        sortNo: '',
        delFlag: '0',
        remarks: '',
        createTime: '',
        updateTime: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
